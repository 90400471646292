.nav-bar-mobile {

    .navbar-brand {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .headline {
            color: white;
            font-weight: bolder;
            font-family: "Barlow", sans-serif;
            font-size: 28px;
            margin-left: 10px;
        }
    
        img {
            width: 24px;
            border-radius: 8px;
        }
    }

    .navbar-nav {
        .nav-link {
            color: white !important;
            margin: 0 10px;
        }
        .user-name {
            font-weight: bold;
            color: #F19A25 !important;
        }
    }
    .navbar-collapse {
        .version-info {
            display: flex;
            justify-content: space-around;
        }
    }

    .navbar-toggler {
        margin-right: 10px;
    }
}
@import '../../../../../../theme/partials/variables';

.sortable-container {
    background-color: #F4F4F4;
    border: 1px solid #efefef;
    border-radius: 3px;
    outline: none;
    margin-top: 10px;

    .sortable-item {
        display: flex;
        align-items: center;
        height: 50px;
        background-color: $whiteTextColor;
        border-bottom: 1px solid #efefef;
        padding: 10px;
    }
}
.modal {
    .modal-dialog {
        width: 70%;
        max-width: 1200px;
        margin: auto;

        .modal-content {
            .modal-header {
                padding: 2rem;
                border: none;
                padding-bottom: 1rem;
                .modal-title {
                    font-family: 'Barlow', sans-serif;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                .modal-btn-container {
                    min-width: 18%;
                    display: flex;
                    justify-content: flex-end;
                }

                .modal-close-btn {
                    background: none;
                    border: none;
                    text-decoration: underline;
                    color: #000000;
                }

            }

            .modal-body {
                padding: 2rem;

                >.container {
                    padding: 0;
                }
            }
        }
    }
}
@import '~bootstrap/scss/bootstrap';
@import "./partials/variables";

.text-primary {
    color: $primary  !important;
}

.nav-link {
    color: $black  !important;
}

.text-body {
    color: $textColor  !important;
}

.light {
    background-color: $light;
}

.btn-primary {
    color: $whiteTextColor  !important;
    background-color: $primary  !important;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    color: $whiteTextColor;
    font-weight: 700;
    font-family: 'Barlow', sans-serif;

    span {
        color: $whiteTextColor;
        font-weight: 700;
    }
}

input,
select {
    font-weight: 500;
    font-family: 'Barlow', sans-serif;
    font-size: 12px;
    line-height: 14px;
}
select {
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 1rem;
    padding-right: 2rem;
}

.loading-indicator:before {
    content: '';
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.loading-indicator:after {
    content: 'Loading...';
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
}
::-webkit-scrollbar {
    width: 0.8em;
    box-sizing: border-box;
  }
   
::-webkit-scrollbar-track {
    box-shadow: none;
    outline: 1px solid #C6C6C6;
  }
::-webkit-scrollbar-thumb {
    background-color: #F19A25;
    outline: 1px solid #C6C6C6;
}
.icon-not-started {
    color: #E6E6E6;
}
.icon-play {
    color: $primary;
}
.icon-pause {
    color: #FFD600;
}
.icon-done {
    color: #7ED00F;
}
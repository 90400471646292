@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Barlow"),
   url("./assets/fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Barlow"),
   url("./assets/fonts/Barlow/Barlow-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Barlow"),
   url("./assets/fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > .sortable-item {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  padding: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "../../../theme/partials/variables";

.archive {
  .filterbar {
    align-items: flex-start;

    .filterbar-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .filter-item {
        width: 10%;
        margin-right: 10px !important;
        margin-bottom: 10px;
        input,
        select {
          border: none;
        }
        &:last-child {
          width: 100%;
        }

        .form-control {
          padding: 0.45rem 0.75rem;
          font-size: 14px;
          line-height: 17px;
          font-weight: normal;
          font-family: "Barlow", sans-serif;
          text-overflow: ellipsis;
        }

        .dropdown {
          .btn.btn-primary {
            padding: 0.45rem 0.75rem !important;

            span {
              font-weight: normal;
            }
          }
        }
      }
        // TODO: Added this to hide process filter from UI
        // .filter-item:nth-child(3) {
        //   display: none;
        // }


      // add full width calss for full width
      &.full-width-left {
        width: 100%;
        margin-bottom: 4px;

        .filter-item {
          width: 8%;
          margin-bottom: 0px;

          &:last-child {
            width: 37%;
            margin-bottom: 0px;
            height: 58px;
            display: flex;
            align-items: flex-end;

            input {
              height: 34px;
            }
          }
        }
      
      }
    }
    .filter-item-right {
      width: 14%;
      margin-left: 10px;

      label {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }

      .dropdown-container {
        width: 100%;

        > .btn {
          padding: 0;
          width: 100%;

          .dropdown {
            width: 100%;

            .btn {
              span {
                font-weight: 900;
              }
            }
          }
        }
      }

      .timer-container {
        margin-top: 5px;
        width: 100%;
        background-color: #fff;
        display: flex;
        padding: 2px 5px;

        span {
          width: 48%;
          display: flex;
          justify-content: flex-start;

          svg {
            padding-right: 8px;
          }
        }
      }
      &.margin-adjust {
        margin-top: 8px;
        margin-right: 10px;
        margin-left: 0;
      }
    }
  }
}

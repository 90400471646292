@import "~bootstrap/scss/functions";
$sizes: ();
@import "~bootstrap/scss/variables";

$primary: #F19A25;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: #F4F4F4;
$dark: $gray-900;
$textColor: $black;
$whiteTextColor: #ffffff;
$darkGray: #0A0A0A;
$black: #000000;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);
.prozess-container {
    background: #F4F4F4 0% 0% no-repeat padding-box;
}

.operations {
    margin-right: 30px;

    .operation-container {
        display: table;
        width: 100%;
        padding: 0px;
        margin: 0px;
        position: relative;
        height: 35px;

        li {
            display: table-cell;
            height: 35px;
            position: relative;
            padding: 0px;
            margin: 0px;
            text-align: center;
            width: 29%;

            &:first-child {
                width: 10%;
                color: black;
                font-family: 'Barlow', sans-serif;
                font-size: 14px;
                position: relative;
                line-height: 35px;
            }

            &:hover {
                background-color: #FF9929;
                cursor: pointer;
                color: white;
                border-color: transparent transparent transparent #FF9929;
            }
        
            .operation-btn {
                font-weight: 900;
                text-transform: uppercase;
                color: black;
                font-family: 'Barlow', sans-serif;
                font-size: 14px;
                line-height: 17px;
                position: relative;
                line-height: 35px;
                /* equal to the list item's height */
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #F4F4F4;
                color: #000;

                &:after,
                &:before {
                    content: "";
                    display: inline-block;
                    border-width: 26px;
                    border-style: solid;
                    width: 0px;
                    height: 0px;
                    left: 100%;
                    top: -9px;
                    position: absolute;
                    z-index: 1;
                    border-color: transparent transparent transparent #fff;
                }

                &:before {
                    border-width: 17px;
                    display: block;
                    border-color: transparent transparent transparent #F4F4F4;
                    z-index: 2;
                    top: 0px;
                }

                &.active-tab {
                    background-color: #FF9929;
                    color: #fff;

                    &:before {
                        border-color: transparent transparent transparent #FF9929;
                    }
                }
            }
            &:first-child{
                &:hover {
                    background-color: #fff;
                    cursor: auto;
                    color: black;
                    border-color: transparent transparent transparent #FF9929;
                }
            }
            &:last-child{
                .operation-btn{
                    &:before, &:after {
                        border-width: 0;
                        top:0;
                    }
                }
             
            }

        }
    }

    .btn {
        color: black;
        font-family: 'Barlow', sans-serif;
        font-size: 14px;
        line-height: 35px;
        text-decoration: underline;
    }
}
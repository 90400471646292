.operator-detail {
    $this: &;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    &-header {
        background-image: linear-gradient(#333333, #000000);
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        .headline {
            color: white;
            font-weight: bolder;
            font-family: 'Barlow', sans-serif;
            font-size: 28px;
            line-height: 41px;
        }

        .humburger {
            color: #F19A00;
            font-size: 24px;
        }
    }

    &-body {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding-bottom: 70px;
        width:382px;
        .body-top {
            display: flex;
            justify-content: space-between;
            padding: 16px 16px 0 16px;
            position: sticky;
            top:68px;
            background-color: #ffffff;
            z-index: 4;
            table {
                width: 382px;
                margin-bottom: 0px;
                
                thead {
                    tr {
                        display: flex;
                        width: 382px;
                        th {
                            font-size: 14px;
                            line-height: 17px;
                            font-weight: bolder;
                            font-family: 'Barlow', sans-serif;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width:calc(28%);
                            &:first-child, &:nth-child(2){
                                width:10%;
                                text-overflow:inherit;
                            }
                        }
                    }

                }

                tbody {
                    tr {
                        display: flex;
                        width: 382px;
                        td {
                            font-size: 14px;
                            line-height: 17px;
                            font-weight: normal;
                            font-family: 'Barlow', sans-serif;
                            width: 382px;

                            .col {
                                display: flex;
                                justify-content: space-between;
                                width: 33%;
                                padding: 0;
                                margin-bottom: 5px;
                            }
                            .col:nth-child(1) {
                                padding-left: 5px;
                            }

                            span {
                                font-weight: bold;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: calc(40%);
                            }
                            p {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: calc(60%);
                                margin-bottom: 5px;
                            }
                        }

                        &:nth-child(odd) {
                            background-color: #F4F4F4;
                        }
                        &:not(.multi-collapse){
                            display: flex;
                            td{
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width:calc(28%);
                                &:first-child, &:nth-child(2){
                                    width:10%;
                                    text-overflow:inherit;
                                }
                            }
                        }
                    }

                }
            }
        }

        .user-detail {
            background-color: #F4F4F4;
            padding: 0 16px 16px 16px;
            width: 100%;
            position: relative;
            .user-row {
                font-size: 14px;
                line-height: 17px;
                font-weight: normal;
                font-family: 'Barlow', sans-serif;
                height: 44px;
                border-bottom: 1px solid #C6C6C6;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-transform: capitalize;
                div {
                    display: block;
                    justify-content: space-between;
                    padding: 10px 5px 10px 0;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 40%;
                    font-weight: 600;

                    input,
                    textarea {
                        height: 34px;
                        width: 80%;
                        border: 1px solid #C6C6C6;
                        border-radius: 3px;
                    }
                    input[type='checkbox']{
                        width:20px;
                        height: 20px;
                    }
                    input[type=checkbox]:checked{
                        background-color: #F19A00;
                    }
                }
                div+div {
                    width: 60%;
                    font-weight: normal;
                }

                &.textarea {
                    width: 100%;
                    position: relative;
                    display: block;
                    height: 100%;
                    border-bottom: none;

                    textarea {
                        width: 100%;
                        padding: 10px;
                        min-height: 120px;
                        border: 1px solid #C6C6C6;
                        border-radius: 3px;
                        resize: vertical;
                        background-color: #ffffff;
                    }
                }
                &:first-child{
                    height: 30px;
                    div{
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
                .user-submit-wrapper{
                    padding-top:16px;
                    display: flex;
                    justify-content: space-between;
                    color: #F19A00;
                    font-size: 18px;
                    width: 100%;
                }
                &.height-auto {
                    height: auto;
                    div+div {
                        white-space: inherit;
                        overflow: auto;
                        text-overflow: inherit;
                    }
                }
            }

        }
    }

    &-footer {
        background-image: linear-gradient(#333333, #000000);
        height: 70px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        position: fixed;
        bottom: 0;
    }
}
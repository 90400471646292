@import "../../../theme/partials/variables";

.planning-container {
  .filterbar {
    align-items: flex-start;

    .filterbar-container {
      width: 40%;
      display: flex;
      flex-wrap: wrap;

      .filter-item {
        width: 23%;
        margin-right: 10px !important;
        margin-bottom: 10px;
        input,
        select {
          border: none;
        }
        &:last-child {
          width: 100%;
          margin-bottom: 0;
        }

        .form-control {
          padding: 0.45rem 0.75rem;
          font-size: 14px;
          line-height: 17px;
          font-weight: normal;
          font-family: "Barlow", sans-serif;
          text-overflow: ellipsis;
        }

        .dropdown {
          .btn.btn-primary {
            padding: 0.45rem 0.75rem !important;

            span {
              font-weight: normal;
            }
          }
        }
      }

      // add full width calss for full width
      &.full-width-left {
        width: 100%;
        margin-bottom: 4px;

        .filter-item {
          width: 8%;
          margin-bottom: 0px;

          &:last-child {
            width: 37%;
            margin-bottom: 0px;
            height: 58px;
            display: flex;
            align-items: flex-end;

            input {
              height: 34px;
            }
          }
        }
        // TODO: Added this to hide process filter from UI
        // .filter-item:nth-child(4) {
        //   display: none;
        // }

      }
    }
    .filter-item-right {
      // width: 9%;
      margin-left: 10px;

      label {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }

      .dropdown-container {
        width: 100%;

        > .btn {
          padding: 0;
          width: 100%;

          .dropdown {
            width: 100%;

            .btn {
              span {
                font-weight: 900;
              }
            }
          }
        }
      }

      .timer-container {
        margin-top: 5px;
        width: 100%;
        background-color: #fff;
        display: flex;
        padding: 2px 5px;

        span {
          width: 48%;
          display: flex;
          justify-content: flex-start;

          svg {
            padding-right: 8px;
          }
        }
      }
      &.margin-adjust {
        margin-top: 8px;
        margin-right: 10px;
        margin-left: 0;
        flex: 1 1 0;
      }
    }
  }
  .order-grid { 
      height: 100%; 
      overflow-y: auto;
      margin-bottom: 40px;

      .ag-theme-alpine {
        height: 36vh !important;

        .header-margin-left {
          margin-left: 20px;
        }
    
        .ag-checkbox-input-wrapper {
          &::after {
            color: #000;
          }
        }
    
        .ag-ltr .ag-cell,
        .ag-header-cell,
        .ag-header-group-cell {
          padding-left: 14px;
        }
    
        .ag-row-odd {
          .ag-cell,
          ag-full-width-row .ag-cell-wrapper.ag-row-group {
            border-top: none;
            border-bottom: none;
          }
        }
    
        // .ag-header-group-cell-label,
        // .ag-header-cell-label {
        //   justify-content: space-between !important;
        // }

        .status-img-header {
          img {
            height: 18px;
          }
        }
    
        .ag-header-group-cell-with-group {
          background-color: #f4f4f4;
        }
    
        .ag-header-row-column-group {
          height: 32px !important;
        }
    
        .ag-header-row:not(:first-child) .ag-header-cell {
          border-top: none;
        }
    
        .custom-header-group .collapsed,
        .custom-header-group .expanded {
          font-size: 18px;
          color: #f19a25 !important;
        }
    
        .ag-cell-next {
          .title-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 6px;
            width: 72%;
    
            span {
              color: $darkGray;
              font-size: 14px;
              line-height: 17px;
              font-weight: 900;
              font-family: "Barlow", sans-serif;
              padding: 0;
              margin: 0;
    
              &.subtitle {
                color: #888888;
                font-size: 9px;
                line-height: 9px;
              }
            }
          }
        }
    
        .ag-header-group-cell:not(.ag-column-resizing)
          + .ag-header-group-cell:not(.ag-header-cell-moving):hover {
          background-color: #f4f4f4;
        }
    
        .row-missing-values {
          background: rgba(255, 0, 0, 0.3);
        }

        // .ag-right-aligned-header {
        //   .ag-header-cell-label {
            // flex-direction: inherit;
        //   }
        // }
      }
  }

}

@import "../../../theme/partials/variables";

.filterbar {
    padding: 20px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .d-flex {
        align-items: center;
    }

    .width-15 {
        width: 15% !important;
    }

    .filter-bar-btn {
        color: $whiteTextColor;
        background-color: $primary;
        padding: 6px 10px;
        border: none;
        border-radius: 3px;

        span {
            color: $whiteTextColor;
            font-weight: 700;
        }

        p {
            padding: 3px 0px;
        }
    }

    label {
        color: $darkGray;
        font-weight: 500;
        font-family: "Barlow", sans-serif;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 14px;
    }

    .selected-process {
        background-color: #0d6efd;

        p {
            color: $whiteTextColor;
        }
    }
}
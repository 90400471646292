.App {
  text-align: center;
  font-family: 'Barlow', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

p,
span,
label,
input {
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0px !important;
  }
  .auth-inner {
    width: 380px;
  }
  .auth-wrapper .container {
    padding: 0px 10px !important;
  }
  .modal-body {
    height: 400px !important;
    overflow-y: scroll !important;
  }
}
.operator {
    $this: &;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    &-header {
        background-image: linear-gradient(#333333, #000000);
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        position: fixed;
        z-index: 1000;

        .headline {
            color: white;
            font-weight: bolder;
            font-family: "Barlow", sans-serif;
            font-size: 28px;
            line-height: 41px;
        }

        .humburger {
            color: #f19a00;
            font-size: 24px;
        }
    }

    &-body {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding-bottom: 70px;

        .body-top {
            display: flex;
            justify-content: space-between;
            width: 382px;
            padding: 16px 0;
            border-bottom: 1px solid #c6c6c6;
            position: fixed;
            background-color: #ffffff;
            z-index: 100;
            .body-top-left,
            .body-top-right {
                display: flex;
                justify-content: space-between;
                font-size: 40px;
                font-weight: bolder;
                font-family: "Barlow", sans-serif;
                color: black;
                align-items: center;

                >div {
                    display: flex;
                    justify-content: space-evenly;
                    flex-direction: column;
                    align-items: center;

                    span {
                        font-size: 21px;
                        font-weight: bolder;
                        font-family: "Barlow", sans-serif;
                        padding: 2px 0;
                    }

                    span+span {
                        font-size: 17px;
                        color: #666666;
                        font-weight: normal;
                    }
                }
            }

            .body-top-left {
                width: 50%;
                max-width: 50%;

                .hover-dropdown {
                    border: 1px solid;
                    margin-left: 5px;
                    border-radius: 4px;
                    width:160px;
                    .title-container{
                       
                        >div{
                           span:not(.subtitle){
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width:calc(100px);
                            text-align: left;
                           }
                           .subtitle {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width:calc(122px);
                            text-align: left;
                           }
                        }
                    }
                }

                >span {
                    font-size: 28px;
                    color: black;
                }
            }

            .body-top-right {
                display: flex;
                justify-content: space-between;
                color: #f19a00;
                align-items: center;
                font-size: 24px;
                width: 42%;

                >span {
                    display: flex;
                    color: #f19a00;
                    align-items: center;
                    width: 40px;
                    font-size: 24px;
                    justify-content: center;
                }

                >div {
                    .custom-date-input {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                    }

                    span {
                        margin-left: 0;
                    }

                    span+span {
                        color: black;
                    }
                }
            }
        }

        .body-table {
            padding: 16px;
            width: 100%;
            margin-top: 77px;
            padding-top: 0;
            table {
                width: 382px;

                thead {
                    position: fixed;
                    background-color: #ffffff;
                    padding-top: 16px;
                    tr {
                        display: flex;
                        width: 382px;
                        th {
                            font-size: 14px;
                            line-height: 17px;
                            font-weight: bolder;
                            font-family: "Barlow", sans-serif;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width:calc(25%);
                            position: relative;
                            &:first-child, &:nth-child(2), &:last-child{
                                width:10%;
                                text-overflow:inherit;
                            }
                        }
                        .btn-expand-collapse {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                tbody {
                    margin-top: 57px;
                    display: block;
                    overflow: hidden;
                    tr {
                        width: 382px;
                        td {
                            font-size: 14px;
                            line-height: 17px;
                            font-weight: normal;
                            font-family: "Barlow", sans-serif;
                            width: 382px;
                           
                            .col {
                                display: flex;
                                justify-content: space-between;
                                width: 33%;
                                padding: 0;
                                margin-bottom: 5px;
                            }
                            .col:nth-child(1) {
                                padding-left: 5px;
                            }

                            span {
                                font-weight: bold;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: calc(50%);
                            }
                            p {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: calc(50%);
                                margin-bottom: 5px;
                            }
                            &:nth-child(2) {
                                background-color: white;
                            }
                        }

                        &:nth-child(odd) {
                            background-color: #f4f4f4;
                        }
                        &:not(.multi-collapse){
                            display: flex;
                            td{
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width:calc(25%);
                                &:first-child, &:nth-child(2), &:last-child{
                                    width:10%;
                                    text-overflow:inherit;
                                    
                                }
                                &:last-child{
                                    color: #F19A00;
                                    font-size: 18px;
                                }
                            }
                        }
                        &.row-priority-high {
                            background: rgba(255, 0, 0, 0.3);
                        }
                        &.row-priority-medium {
                            background: rgba(255, 214, 0, 0.3);
                        }
                    }
                }
            }
        }
    }

    &-footer {
        background-image: linear-gradient(#333333, #000000);
        height: 70px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        position: fixed;
        bottom: 0;
        max-width: 600px;
    }
}
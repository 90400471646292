@import '../../../theme/partials/variables';

.action {
    display: flex;
    justify-content: space-between;
    .action-left {
        display: flex;
        .disableBtn{
            color:#C6C6C6;
        }
        .disableBtnBg{
            background-color:#C6C6C6 !important;
            color:#fff;
        }
        .btn-group {
            .dropdown-menu {
                transform: translate3d(0px, 37px, 0px) !important;
                padding: 0 !important;
                a {
                    font-weight: bold;
                }
            }
        }
    }

    .action-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 8%;
        .action-right1 {
            font-size: 12px;
            span {
                color: black;
                font-family: 'Barlow', sans-serif;
                font-size: 12px;
                line-height: 14px;
                margin-left: 8px;
            }
        }

        .action-right2 {
            color: #80CE2B;
            font-size: 14px;
            span {
                color: black;
                margin-left: 8px;
                font-family: 'Barlow', sans-serif;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    .btn-primary {
        border-color: none;
    }

    .btn-secondary {
        background-color: transparent;
        border: 2px solid #C6C6C6;
        color: #000;
        padding: 0.25rem 0.65rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
    }

    .action-item {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $darkGray;
        font-size: 16px;
        line-height: 14px;
        margin-right: 0 !important;
        margin-left: 1rem !important;

        span {
            color: $darkGray;
            font-size: 12px;
            line-height: 14px;
        }
    }
}

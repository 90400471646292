.process-step-attribute-list {
    .ag-header-group-cell-with-group, .ag-header-cell, .ag-cell {
        border-left: solid 1px #C6C6C6;
        border-right: 0;
    }
    .header-align-center {
        .ag-header-cell-label {
            justify-content: center;
        }
    }
    .ag-root-wrapper {
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    }
}
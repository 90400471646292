@import '../../../../theme/partials/variables';

.process-accordian {
    .accordion-header {
        .accordion-button {
            display: flex;
            width:100%;
            >div{
                width:25%;
            }
            div+div{
                display: flex;
                width: 70%;
                justify-content: flex-end;
            }
            &:not(.collapsed) {
                background-color: transparent;
               
            }
            // &.collapsed{
            //     display: flex;
            //     width:100%;
            //     >div{
            //         width:25%;
            //     }
            //     div+div{
            //         display: flex;
            //         width: 70%;
            //         justify-content: flex-end;
            //     }
            // }
        }
    }
}
.detail-modal {
    .modal-dialog {
        width: 82%;
        max-width:1400px;
        .modal-content {
            .modal-header {
                padding: 2.6rem;
                border: none;
                padding-bottom: 1rem;
                .modal-title {
                    font-family: 'Barlow', sans-serif;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                .modal-btn-container {
                    .modal-close-btn {
                        color: black;
                        font-family: 'Barlow', sans-serif;
                        font-size: 14px;
                        line-height: 35px;
                        text-decoration: underline;
                        background-color: transparent;
                        border: none;
                    }

                    button:last-child {
                        margin-right: 0px !important;
                    }
                }


            }

            .modal-body {
                padding: 2.6rem;
                padding-top: 0;;
                .modal-body-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    .modal-body-content {
                        display: flex;
                        flex-direction: column;
                        width: 23%;

                        .modal-inner-wrapper {
                            height: 380px;
                            overflow-y: auto;
                            overflow-x: hidden;
                            padding-right: 4px;
                            .body-inner {
                                color: black;
                                font-family: 'Barlow', sans-serif;
                                font-size: 14px;
                                line-height: 17px;
                                font-weight: normal;
                                padding: 4px 0;
                                border-bottom: 1px solid #C6C6C6;
                                display: flex;
                                flex-direction: column;
                                margin-right: 2px;
                                justify-content: space-between;
                                word-break: break-word;

                                span {
                                    padding: 0.6rem 0;
                                    align-items: center;
                                    width: 100%;
                                    font-weight: 600;

                                    .title-container {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        margin-left: 6px;
                                        width: 72%;

                                        span {
                                            color: black;
                                            font-size: 14px;
                                            line-height: 17px;
                                            font-weight: 900;
                                            font-family: 'Barlow', sans-serif;
                                            padding: 0;
                                            margin: 0;
                                            width: 100%;
                                            overflow: hidden;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;

                                            &.subtitle {
                                                color: #888888;
                                                font-size: 9px;
                                                line-height: 9px;
                                            }
                                        }
                                    }
                                    &.error {
                                        color:red;
                                        font-weight: bolder;
                                    }
                                }

                                .process-content {
                                    display: flex;
                                    padding: 0.2rem;
                                    width: 40%;

                                    span {
                                        padding: 0;
                                        margin: 0;
                                        font-weight: normal;

                                        text-overflow: ellipsis;
                                    }

                                    >span {
                                        text-transform: lowercase;
                                    }

                                    &:first-child {
                                        color: #E6E6E6;
                                        border-radius: 50%;
                                        font-size: 1.2rem;
                                        width: 15%;
                                        background-color: #fff;

                                    }
                                }

                                span+span {
                                    background-color: #F4F4F4;
                                    border-radius: 3px;
                                    padding: 0.6rem;
                                    font-weight: normal;
                                    .error{
                                        color:red;
                                        border: 1px solid red;
                                    }
                                }

                                span.react-datepicker__navigation-icon {
                                    padding: 0;
                                    width: 0;
                                }
                            }
                            &::-webkit-scrollbar {
                                width: 0.5em;
                                margin-left: 0.5rem;
                            }
                        }
                        .process-step-list-wrapper {
                            height: 100%;
                            min-height: 300px !important;
                            max-height: 380px !important;

                            .body-inner {
                                flex-direction: row;
                            }

                            .status-dropdown {
                                display: flex;
                                align-items: center;

                                .dropdown-toggle {
                                    padding: 2px;
                                    line-height: 0px;
                                    height: 20px;
                                    width: 20px;
                                }

                                .dropdown-menu {
                                    min-width: 2rem;
                                }
                            }
                        }

                        .process-btn-container {
                            display: flex;
                            justify-content: space-between;
                            color: #F19A25;
                            margin-top: 24px;
                            align-items: center;
                            a {
                                color: black;
                                font-size: 14px;
                                line-height: 17px;
                                font-weight: 900;
                                font-family: 'Barlow', sans-serif;
                                margin-left: 6px;
                                font-weight: normal;
                                text-decoration: underline;
                            }
                        }

                        &:last-child {
                            width: 26%;
                        }

                        .body-inner-title {
                            text-transform: uppercase;
                            font-weight: 900;
                            padding: 1rem 0;
                            font-family: 'Barlow', sans-serif;

                            &.error {
                                color:red;
                                font-weight: bolder;
                            }
                        }
                    }
                }
            }

            .modal-footer {
                background-color: #E6E6E6;
                padding: 1rem;

                >div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .gray-btn {
                        border: 2px solid #C6C6C6;
                        font-family: 'Barlow', sans-serif;
                        font-weight: 700;
                        text-transform: uppercase;
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }

        }
    }
}
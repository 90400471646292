.custom-header-group {
  .customExpandButton {
    width: 28px;
    height: 16px;
    cursor: pointer;

    &.collapsed {
      &::after {
        content: "";
        width: 28px;
        height: 16px;
        background: url("../../../assets/images/arrows-strech-column.svg")
          no-repeat center fixed;
        display: inline-block;
        position: absolute;
      }
    }
    &.expanded {
      &::after {
        content: "";
        width: 28px;
        height: 26px;
        background: url("../../../assets/images/arrows-close-column.svg")
          no-repeat center fixed;
        display: inline-block;
        position: absolute;
        top: 3px;
      }
    }
  }
  .customHeaderLabel {
    font-size: 16px;
    color: black;
    font-family: "Barlow", sans-serif;
    line-height: 19px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }
}

@import "../../../theme/partials/variables";

.ag-theme-alpine {
    .ag-root-wrapper {
        border: none;
        // box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    }

    .ag-header {
        background-color: $whiteTextColor;
        border-bottom: none;
    }

    .ag-row {
        border-bottom: none;
    }

    .ag-row-odd {
        background-color: #f4f4f4;
    }

    .ag-header-cell-text {
        text-transform: uppercase;
    }

    .ag-action-cell {
        padding-top: 11px;
    }

    .ag-header-cell {
        border-bottom: 1px solid #c6c6c6;
    }

    .ag-header-cell,
    .ag-cell {
        border-left: solid 2px #ffffff;
        border-right: solid 2px #ffffff;
    }

    .ag-cell-wrap-text {
        display: flex;
    }

    .ag-header-group-cell-with-group {
        border-left: 20px solid white;
    }

    .ag-icon-none::before,
    .ag-icon-asc::before,
    .ag-icon-desc::before {
        color: #f19a25;
    }
}
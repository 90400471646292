.navbar-brand-logo {
    padding: 0.5rem 0 !important;
    display: flex;
    flex-direction: column;

    img {
        width: 217px;
        height: auto;
    }

    .version-info {
        margin-left: 10px;
    }
}

.language-selector .dropdown-menu {
    min-width: auto;
}

.mode-language-container {
    display: flex;
    .help-switch {
        display: flex;
        align-items: center;
        margin-right: 20px;
        font-size: 14px;
        min-width: 140px;

        .form-switch {
            display: flex;
            .form-check-input {
                height: 1.5em;
                width: 3em;
                margin-right: 5px;
                
                &:checked {
                    background-color: #F19A25 !important;
                    border-color: #F19A25 !important;
                }
            }
        }
    }
}
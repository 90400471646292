.settings-accordian {
    .accordion-header {
        .accordion-button {
            .accordian-title {
                font-weight: 900;
                text-transform: uppercase;
                color: black;
                font-family: 'Barlow', sans-serif;
                text-transform: uppercase;
                font-size: 14px;
                line-height: 17px;
                margin-left: 8px;
                display: flex;
                align-items: center;

                &:before {
                    content: "";
                    background: url(../../../assets/images/order.svg);
                    height: 20px;
                    width: 20px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    display: inline-block;
                    margin-right: 8px;
                }
            }

            &:not(.collapsed) {
                background-color: transparent;
            }
            &.collapsed{
                display: flex;
                width:100%;
                div+div{
                    display: flex;
                    width: 93%;
                    justify-content: flex-end;
                }
            }
        }
    }

    .accordian-body-container {
        padding: 20px 25px;

        >div {
            div {
                display: flex;

                .img {
                    &:before {
                        content: "";
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: left;
                        display: inline-block;
                        margin-right: 12px;
                        box-sizing: content-box;
                        padding-left: 0;
                        background-repeat: no-repeat;
                    }
                }

                .list-container {
                    display: flex;
                    flex-direction: column;
                    border-left: solid 3px #F19A25;
                    padding-left: 12px;

                    p {
                        font-family: 'Barlow', sans-serif;
                        text-transform: uppercase;
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 700;
                    }

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        li {
                            a {
                                font-family: 'Barlow', sans-serif;
                                font-size: 16px;
                                line-height: 24px;
                                text-decoration: underline;
                                color: #000000;
                                cursor: pointer;

                                &:hover, &.active-tab {
                                    font-weight: 700;
                                    text-decoration-color: #F19A25;
                                    text-decoration-thickness: 3px;
                                }
                            }

                        }
                    }
                }

                &.general {
                    .img {
                        &:before {
                            background-image: url(../../../assets/images/user-solid.svg);
                            height: 40px;
                            width: 35px;

                        }
                    }

                    .list-container {
                        ul {
                            li {
                                a {
                                    &:hover, &.active-tab {
                                        text-decoration-color: #F19A25;
                                    }
                                }
                            }
                        }
                    }

                }

                &.asset {
                    .img {
                        &:before {
                            background-image: url(../../../assets/images/asset.svg);
                            height: 35px;
                            width: 40px;

                        }
                    }

                    .list-container {
                        border-left: solid 3px #5373BA;

                        ul {
                            li {
                                a {
                                    &:hover, &.active-tab {
                                        text-decoration-color: #5373BA;
                                    }
                                }
                            }
                        }
                    }
                }

                &.order {
                    .img {
                        &:before {
                            background-image: url(../../../assets/images/order.svg);
                            height: 40px;
                            width: 30px;

                        }
                    }

                    .list-container {
                        border-left: solid 3px #E52921;

                        ul {
                            li {
                                a {
                                    &:hover {
                                        text-decoration-color: #E52921;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.hover-dropdown {
    width: 100%;
    .form-control{
        box-shadow: 1px 1px 3px #ccc;
    }
    .btn {
        color: #000;
        font-size: 18px;
        width: 100%;
        background-color: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 72%;

            >div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                span {
                    color: #000;
                    font-size: 14px;
                    line-height: 17px;
                    font-family: "Barlow", sans-serif;
                    padding: 0;
                    margin: 0;

                    &.subtitle {
                        color: #888888;
                        font-size: 9px;
                        line-height: 9px;
                    }
                }
            }
        }

        &.btn-primary {
            background-color: #fff !important;

            &.btn {
                color: #000 !important;
                font-family: "Barlow", sans-serif;
                font-size: 14px;
                line-height: 24px;
                font-weight: normal;
                justify-content: space-between;
                padding: 4px 5px;

                img {
                    width: 25px;
                    height: auto;
                    margin-right: 8px;
                }
            }

            &:after {
                border-top: 0.5em solid;
                border-right: 0.4em solid transparent;
                border-bottom: 0;
                border-left: 0.4em solid transparent;
            }
        }
    }

    .dropdown-menu {
        width: 100%;
        border: none;
        color: #000 !important;
        font-family: "Barlow", sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: normal;

        img {
            width: 25px;
            height: auto;
            margin-right: 8px;
        }
        > a{
            padding: 0.25rem;
            .title-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 72%;
    
                >div {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
    
                    span {
                        color: #000;
                        font-size: 14px;
                        line-height: 17px;
                        font-family: "Barlow", sans-serif;
                        padding: 0;
                        margin: 0;
    
                        &.subtitle {
                            color: #888888;
                            font-size: 9px;
                            line-height: 9px;
                        }
                    }
                }
            }
            &:active{
                background-color: #E3E3E3;
            }
        }
        
    }
}
@import '../../../theme/partials/variables';

.navbar-expand {
  .navbar-nav {
    .nav-link {
      font-family: 'Barlow', sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      font-weight: 700;
    }
  }
}

.navbar.grey-grad {
  background: transparent linear-gradient(180deg, #F6F6F6 0%, #E3E3E3 100%) 0% 0% no-repeat padding-box;
  padding-top: 20px;
  padding-bottom: 0;
}

.navbar-expand .navbar-nav .nav-link {
  padding: 0.7rem 1rem !important;
}

.navbar-expand .navbar-nav .nav-link:active,
.navbar-expand .navbar-nav .nav-link:hover {
  background-color: $whiteTextColor;
}

.navbar-expand .navbar-nav .nav-link.active-menu {
  background-color: $whiteTextColor;
  color: $primary  !important;
}

.navbar-expand .navbar-collapse {
  flex-direction: column !important;
  align-items: flex-end;
}

.navbar-expand .navbar-collapse .navbar-text {
  margin-bottom: 0.2rem !important;
}